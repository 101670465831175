import React from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';

const Stats = ({ stats }) => {
  if (!stats) {
    return <p>No statistics available.</p>;
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Data Statistics
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle1">Average:</Typography>
            <Typography variant="h6">{stats.average ?? 'N/A'}</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle1">Minimum:</Typography>
            <Typography variant="h6">{stats.min ?? 'N/A'}</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle1">Maximum:</Typography>
            <Typography variant="h6">{stats.max ?? 'N/A'}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Stats;
