import React from 'react';
import { List, ListItem, ListItemText, Divider, Button } from '@mui/material';

const TargetList = ({ targets, onEdit, onDelete }) => {
  if (!targets.length) {
    return <p>No targets available.</p>;
  }

  return (
    <List>
      {targets.map((target) => (
        <React.Fragment key={target.id}>
          <ListItem>
            <ListItemText
              primary={`Target Value: ${target.target_value}`}
              secondary={`Start Date: ${new Date(target.start_date).toLocaleDateString()} - End Date: ${new Date(target.end_date).toLocaleDateString()}`}
            />
            <Button color="primary" onClick={() => onEdit(target)}>Edit</Button>
            <Button color="secondary" onClick={() => onDelete(target.id)}>Delete</Button>
          </ListItem>
          <Divider />
        </React.Fragment>
      ))}
    </List>
  );
};

export default TargetList;
