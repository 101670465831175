import React, { useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';

const DataForm = ({ onAddData }) => {
  const [error, setError] = useState('');

  const handleButtonClick = async (value) => {
    setError(''); // Reset error state

    try {
      await onAddData({ value });
    } catch (error) {
      setError('Failed to add data. Please try again.');
    }
  };

  return (
    <div style={{ marginTop: '20px', textAlign: 'center' }}>
      <Typography variant="h5" gutterBottom>
        How are you feeling today?
      </Typography>

      {error && <Typography color="error">{error}</Typography>}

      {/* Buttons in a single row */}
      <Grid container spacing={1} justifyContent="center" alignItems="center">
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => handleButtonClick(1)}>
            😊 <br /> Happiest
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => handleButtonClick(2)}>
            🙂 <br /> Good
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => handleButtonClick(3)}>
            😐 <br /> Neutral
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => handleButtonClick(4)}>
            😓 <br /> Exhausted
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => handleButtonClick(5)}>
            🤒 <br /> Sick
          </Button>
        </Grid>
      </Grid>

      <Typography variant="body2" style={{ marginTop: '20px' }}>
        1 is the happiest, 4 is the most exhausted, and 5 is sick or exhausted.
      </Typography>
    </div>
  );
};

export default DataForm;
