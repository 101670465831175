import React from 'react';
import { List, ListItem, ListItemText, Divider } from '@mui/material';

const DataList = ({ dataEntries }) => {
  if (!dataEntries.length) {
    return <p>No data entries available.</p>;
  }

  return (
    <List>
      {dataEntries.map((entry) => (
        <React.Fragment key={entry.id}>
          <ListItem>
            <ListItemText
              primary={`Data Value: ${entry.data_value}`}
              secondary={`Entry Date: ${new Date(entry.entry_date).toLocaleDateString()}`}
            />
          </ListItem>
          <Divider />
        </React.Fragment>
      ))}
    </List>
  );
};

export default DataList;
