import React, { useState, useEffect } from 'react';
import { getDataBetweenDates, addData, getDataStats } from '../services/api';
import DataForm from '../components/DataForm';
import DataList from '../components/DataList';
import Stats from '../components/Stats';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { TextField, Button, Grid } from '@mui/material';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DataPage = () => {
  const [dataEntries, setDataEntries] = useState([]);
  const [stats, setStats] = useState({});
  const [error, setError] = useState('');
  const [startDate, setStartDate] = useState('2024-01-01');
  const [endDate, setEndDate] = useState('2024-12-31');
  const [timeRange, setTimeRange] = useState('last7Days');

  useEffect(() => {
    fetchData();
    fetchStats(timeRange);
  }, [startDate, endDate, timeRange]);

  const fetchData = async () => {
    try {
      const data = await getDataBetweenDates(startDate, endDate);
      setDataEntries(data);
    } catch (error) {
      setError('Failed to fetch data.');
    }
  };

  const fetchStats = async (range) => {
    try {
      const statsData = await getDataStats(range);
      setStats(statsData);
    } catch (error) {
      setError('Failed to fetch statistics.');
    }
  };

  const handleAddData = async (dataEntry) => {
    try {
      await addData(dataEntry);
      fetchData(); // Refresh the data list after adding new data
    } catch (error) {
      setError('Failed to add data.');
    }
  };

  const handleTimeRangeChange = (event) => {
    setTimeRange(event.target.value);
    fetchStats(event.target.value);
  };

  // Prepare data for the plot
  const plotData = {
    labels: dataEntries.map((entry) => new Date(entry.entry_date).toLocaleDateString()), // X-axis labels (dates)
    datasets: [
      {
        label: 'Data Value',
        data: dataEntries.map((entry) => entry.data_value), // Y-axis values (data values)
        fill: false,
        borderColor: 'rgba(75, 192, 192, 1)',
        tension: 0.1,
      },
    ],
  };

  return (
    <div>
      <h2>Manage Data</h2>

      {/* Time Range Selector for Stats */}
      <div style={{ marginTop: '20px' }}>
        <label>Choose Time Range for Stats:</label>
        <select value={timeRange} onChange={handleTimeRangeChange}>
          <option value="last7Days">Last 7 Days</option>
          <option value="thisWeek">This Week</option>
          <option value="last2Weeks">Last 2 Weeks</option>
          <option value="thisMonth">This Month</option>
          <option value="lastMonth">Last Month</option>
          <option value="thisYear">This Year</option>
        </select>
      </div>

      {/* Display Statistics */}
      <Stats stats={stats} />

      {/* Display Data Trend Plot */}
      <div style={{ margin: '20px 0' }}>
        <h3>Data Trend</h3>
        <Line data={plotData} />
      </div>

      {/* Form to Add Data */}
      <DataForm onAddData={handleAddData} />




    </div>
  );
};

export default DataPage;
