import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { getDataBetweenDates, getDataStats, addData } from '../services/api'; // Import your API functions
import DataForm from './DataForm';

const DataDialog = ({ open, onClose }) => {
  const [dataEntries, setDataEntries] = useState([]);
  const [stats, setStats] = useState({});
  const [error, setError] = useState('');
  const [dialogOpen, setDialogOpen] = useState(open);
  const [lastDataEntry, setLastDataEntry] = useState(null);

  // Fetch data from the server
  const fetchData = async () => {
    try {
      const data = await getDataBetweenDates('2024-01-01', '2024-12-31'); // Example date range
      setDataEntries(data);
      if (data.length > 0) {
        const lastEntry = data[data.length - 1];
        const lastEntryDate = new Date(lastEntry.entry_date);
        setLastDataEntry(lastEntryDate);

        // Check if the last entry was more than 12 hours ago
        const now = new Date();
        if ((now - lastEntryDate) / (1000 * 60 * 60) >= 12) {
          setDialogOpen(true); // Open the dialog if it's been 12 hours since the last entry
        }
      } else {
        // If no data exists, open the dialog
        setDialogOpen(true);
      }
    } catch (error) {
      setError('Failed to fetch data.');
    }
  };

  // Fetch stats from the server
  const fetchStats = async () => {
    try {
      const statsData = await getDataStats();
      setStats(statsData);
    } catch (error) {
      setError('Failed to fetch statistics.');
    }
  };

  // Add new data entry
  const handleAddData = async (data) => {
    try {
      await addData(data);
      setLastDataEntry(new Date()); // Set the current time as the last entry time
      fetchData(); // Refresh data
      fetchStats(); // Refresh stats
      setDialogOpen(false); // Close the dialog after adding data
    } catch (error) {
      setError('Failed to add data.');
    }
  };

  // Only check for data when the component mounts
  useEffect(() => {
    fetchData(); // Fetch initial data on mount
    fetchStats(); // Fetch initial stats on mount
  }, []);

  return null;
  
  return (
    <>
      {/* Main DataDialog component */}
      <Dialog open={dialogOpen} onClose={onClose} fullWidth>
        <DialogTitle>Log Your Mood</DialogTitle>
        <DialogContent>
          {error && <Typography color="error">{error}</Typography>}
          <Typography variant="body1">How are you feeling right now?</Typography>
          <DataForm onAddData={handleAddData} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Footer and stats can be included here */}
      <footer>
        <Typography variant="body2" style={{ textAlign: 'center', marginTop: '20px' }}>
          Average Mood: {stats.average ?? 'N/A'} | Minimum: {stats.min ?? 'N/A'} | Maximum: {stats.max ?? 'N/A'}
        </Typography>
      </footer>
    </>
  );
};

export default DataDialog;
