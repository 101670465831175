import React from 'react';
import UserSettings from '../components/UserSettings';

const UserSettingsPage = () => {
  return (
    <div>
      <h2>User Settings</h2>
      <UserSettings />
    </div>
  );
};

export default UserSettingsPage;
