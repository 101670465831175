import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { Container, Typography, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  useEffect(() => {
    const performLogout = () => {
      logout()
        .then(() => {
          navigate('/'); // Redirect to home or login page after successful logout
        })
        .catch((err) => {
          setError('Failed to log out. Please try again.');
          console.error('Logout error:', err);
        });
    };

    performLogout();
  }, [logout, navigate]);

  return (
    <Container maxWidth="sm" style={{ marginTop: '20px', textAlign: 'center' }}>
      <Typography variant="h5" gutterBottom>
        Logging out...
      </Typography>
      {!error ? (
        <CircularProgress style={{ marginTop: '20px' }} />
      ) : (
        <Typography variant="body1" color="error" style={{ marginTop: '20px' }}>
          {error}
        </Typography>
      )}
    </Container>
  );
};

export default Logout;
