import React, { useState, useEffect } from 'react';
import { listDevices, addDevice, removeDevice } from '../services/api';
import DeviceForm from '../components/DeviceForm';
import DeviceList from '../components/DeviceList';
import { CircularProgress, Alert } from '@mui/material';

const DevicesPage = () => {
  const [devices, setDevices] = useState([]); // Initialize devices as an empty array
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchDevices();
  }, []);

  const fetchDevices = async () => {
    setLoading(true);
    setError('');
    try {
      const deviceData = await listDevices();
      setDevices(Array.isArray(deviceData) ? deviceData : []); // Ensure deviceData is an array
    } catch (error) {
      setError('Failed to fetch devices.');
    } finally {
      setLoading(false);
    }
  };

  const handleAddDevice = async (device) => {
    setLoading(true);
    setError('');
    try {
      await addDevice(device);
      fetchDevices(); // Refresh the device list after adding a new device
    } catch (error) {
      setError('Failed to add device.');
      setLoading(false);
    }
  };

  const handleRemoveDevice = async (deviceId) => {
    setLoading(true);
    setError('');
    try {
      await removeDevice(deviceId);
      fetchDevices(); // Refresh the device list after removing a device
    } catch (error) {
      setError('Failed to remove device.');
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>Manage Devices</h2>
      {error && <Alert severity="error" style={{ marginBottom: '10px' }}>{error}</Alert>}
      {loading && <CircularProgress />}
      {!loading && (
        <>
          <DeviceForm onAddDevice={handleAddDevice} />
          <DeviceList devices={devices} onRemoveDevice={handleRemoveDevice} />
        </>
      )}
    </div>
  );
};

export default DevicesPage;
