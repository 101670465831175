import React, { useState, useEffect } from 'react';
import { getOverallStats } from '../services/api';
import Stats from '../components/Stats';
import { Grid, Select, MenuItem, FormControl, InputLabel, Typography } from '@mui/material';

const StatsPage = () => {
  const [stats, setStats] = useState({});
  const [error, setError] = useState('');
  const [timeRange, setTimeRange] = useState('last7Days'); // Default time range

  useEffect(() => {
    fetchStats(timeRange);
  }, [timeRange]);

  const fetchStats = async (range) => {
    try {
      const statsData = await getOverallStats(range); // Pass the selected time range
      setStats(statsData);
    } catch (error) {
      setError('Failed to fetch statistics.');
    }
  };

  const handleTimeRangeChange = (event) => {
    setTimeRange(event.target.value);
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Overall Statistics
      </Typography>
      {error && <Typography color="error">{error}</Typography>}

      {/* Time Range Selector */}
      <FormControl fullWidth margin="normal">
        <InputLabel>Select Time Range</InputLabel>
        <Select value={timeRange} onChange={handleTimeRangeChange}>
          <MenuItem value="last7Days">Last 7 Days</MenuItem>
          <MenuItem value="thisWeek">This Week (Starting Monday)</MenuItem>
          <MenuItem value="last2Weeks">Last 2 Weeks</MenuItem>
          <MenuItem value="thisMonth">This Month (Starting 1st)</MenuItem>
          <MenuItem value="lastMonth">Last Month</MenuItem>
          <MenuItem value="thisYear">This Year (Starting 1st January)</MenuItem>
        </Select>
      </FormControl>

      {/* Display Statistics */}
      <Stats stats={stats} />
    </div>
  );
};

export default StatsPage;
