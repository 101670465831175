import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const Header = () => {
  const { currentUser, logout } = useAuth();

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography component={Link} to="/" variant="h6" style={{colour:"#fff", flexGrow: 1 }}>
          Wavesync
        </Typography>
        {currentUser ? (
          <>
            <Button color="inherit" component={Link} to="/devices">Devices</Button>
            <Button color="inherit" component={Link} to="/data">Data</Button>
            {/*<Button color="inherit" component={Link} to="/targets">Targets</Button>*/}
            <Button color="inherit" component={Link} to="/stats">Stats</Button>
            <Button color="inherit"   component={Link} to="/logout">Logout</Button>
          </>
        ) : (
          <>
            <Button color="inherit" component={Link} to="/">Login</Button>
            {/*<Button color="inherit" component={Link} to="/terms">Terms</Button>
            <Button color="inherit" component={Link} to="/privacy">Privacy</Button>*/}
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
