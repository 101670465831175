import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  Alert,
} from '@mui/material';

const DeviceForm = ({ onAddDevice }) => {
  const [open, setOpen] = useState(false);
  const [deviceName, setDeviceName] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDeviceName('');
    setApiKey('');
    setLoading(false);
    setError('');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');

    try {
      // Simulate an async operation like API request
      await new Promise((resolve, reject) => setTimeout(resolve, 1000)); // Replace with actual API call if needed

      if (!apiKey) {
        throw new Error('API Key is required.');
      }

      onAddDevice({ deviceName, apiKey });
      handleClose();
    } catch (e) {
      setError(e.message || 'An error occurred. Please try again.');
      setLoading(false);
    }
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Add Device
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Device</DialogTitle>
        <DialogContent>
          {error && <Alert severity="error">{error}</Alert>}
          <form onSubmit={handleSubmit}>
            <TextField
              label="Device Name"
              value={deviceName}
              onChange={(e) => setDeviceName(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="API Key"
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
              required
              fullWidth
              margin="normal"
            />
            <DialogActions>
              <Button onClick={handleClose} color="secondary" disabled={loading}>
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary" disabled={loading}>
                {loading ? <CircularProgress size={24} /> : 'Add Device'}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DeviceForm;
