import axios from 'axios';
import { getIdToken } from './firebaseServices';
//import { useAuth } from '../context/AuthContext'; // Adjust this import based on your actual file structure


const baseURL = 'https://spried-api-wavesync.web.app'


// Function to get user settings
export const getUserSettings = async () => {
  const token = await getIdToken();
  const config = {
    method: 'get',
    url: `${baseURL}/users/settings`,
    headers: { 'Authorization': `${token}` }
  };
  const response = await axios(config);


  return response.data;
};

// Function to update user settings
export const updateUserSettings = async (settings) => {
  const token = await getIdToken();
  const config = {
    method: 'put',
    url: `${baseURL}/users/settings`,
    headers: { 'Authorization': `${token}` },
    data:settings
  };
  const response = await axios(config);

//  const response = await api.put('/users/settings', settings);
  return response.data;
};

// Function to delete user account
export const deleteUserAccount = async () => {
  const token = await getIdToken();
  const config = {
    method: 'delete',
    url: `${baseURL}/users/account`,
    headers: { 'Authorization': `${token}` }
  };
  const response = await axios(config);

//  const response = await api.delete('/users/account');
  return response.data;
};

// Functions for devices
export const listDevices = async () => {
  const token = await getIdToken();
  const config = {
    method: 'get',
    url: `${baseURL}/devices`,
    headers: { 'Authorization': `${token}` }
  };
  const response = await axios(config);

//  const response = await api.get('/devices');
  return response.data;
};

export const addDevice = async (device) => {
  const token = await getIdToken();
  const config = {
    method: 'post',
    url: `${baseURL}/devices`,
    headers: { 'Authorization': `${token}` },
    data:device
  };
  const response = await axios(config);

//  const response = await api.post('/devices', device);
  return response.data;
};

export const removeDevice = async (deviceId) => {
  const token = await getIdToken();
  const config = {
    method: 'delete',
    url: `${baseURL}/devices/${deviceId}`,
    headers: { 'Authorization': `${token}` }
  };
  const response = await axios(config);

//  const response = await api.delete(`/devices/${deviceId}`);
  return response.data;
};

// Functions for data management
export const getDataBetweenDates = async (startDate, endDate) => {
  const token = await getIdToken();
  const config = {
    method: 'get',
    url: `${baseURL}/data?startDate=${startDate}&&endDate=${endDate}`,
    headers: { 'Authorization': `${token}` },
    //data:{ params: { startDate, endDate } }
  };
  const response = await axios(config);

//  const response = await api.get(`/data`, { params: { startDate, endDate } });
  return response.data;
};

// Function to get new device data
export const getNewDeviceData = async () => {
  const token = await getIdToken();
  const config = {
    method: 'get',
    url: `${baseURL}/devices/new`,
    headers: { 'Authorization': `${token}` }
  };
  const response = await axios(config);

  return response.data;
};


export const getDataStats = async () => {
  const token = await getIdToken();
  const config = {
    method: 'get',
    url: `${baseURL}/data/stats`,
    headers: { 'Authorization': `${token}` }
  };
  const response = await axios(config);

//  const response = await api.get('/data/stats');
  return response.data;
};

export const addData = async (data) => {
  const token = await getIdToken();
  const config = {
    method: 'post',
    url: `${baseURL}/data`,
    headers: { 'Authorization': `${token}` },
    data:data
  };
  const response = await axios(config);

//  const response = await api.post('/data', data);
  return response.data;
};

// Functions for targets
export const listTargets = async () => {
  const token = await getIdToken();
  const config = {
    method: 'get',
    url: `${baseURL}/targets`,
    headers: { 'Authorization': `${token}` }
  };
  const response = await axios(config);

//  const response = await api.get('/targets');
  return response.data;
};

export const setTarget = async (target) => {
  const token = await getIdToken();
  const config = {
    method: 'post',
    url: `${baseURL}/targets`,
    headers: { 'Authorization': `${token}` },
    data:target
  };
  const response = await axios(config);

//  const response = await api.post('/targets', target);
  return response.data;
};

export const updateTarget = async (targetId, target) => {
  const token = await getIdToken();
  const config = {
    method: 'put',
    url: `${baseURL}/targets/${targetId}`,
    headers: { 'Authorization': `${token}` },
    data:target
  };
  const response = await axios(config);

//  const response = await api.put(`/targets/${targetId}`, target);
  return response.data;
};

export const deleteTarget = async (targetId) => {
  const token = await getIdToken();
  const config = {
    method: 'delete',
    url: `${baseURL}/targets/${targetId}`,
    headers: { 'Authorization': `${token}` }
  };
  const response = await axios(config);

//  const response = await api.delete(`/targets/${targetId}`);
  return response.data;
};

// Function to get overall stats
export const getOverallStats = async () => {
  const token = await getIdToken();
  const config = {
    method: 'get',
    url: `${baseURL}/stats`,
    headers: { 'Authorization': `${token}` }
  };
  const response = await axios(config);

//  const response = await api.get('/stats');
  return response.data;
};
