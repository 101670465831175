import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CssBaseline, Container } from '@mui/material';

import { useAuth } from './context/AuthContext'; // Adjust this import based on your actual file structure
import Loading from './components/Loading';

import NewDevice from './pages/NewDevice';
import HomePage from './pages/HomePage';
import DevicesPage from './pages/DevicesPage';
import DataPage from './pages/DataPage';
import TargetsPage from './pages/TargetsPage';
import StatsPage from './pages/StatsPage';
import UserSettingsPage from './pages/UserSettingsPage';

import Privacy from './pages/PrivacyPage';
import Terms from './pages/TermsPage';

import Login from './pages/auth/Login';
import Logout from './pages/auth/Logout';
import Page404 from './pages/Page404';

import Header from './components/Header'; // Import Header component
import Footer from './components/Footer'; // Import Footer component

import DataDialog from './components/DataDialog';




function App() {
  const { currentUser, loading } = useAuth(); // Get the loading state and current user from useAuth

  // Show loading spinner while waiting for authentication state
  if (loading) {
    return <Loading />;
  }

  // If no user is authenticated, show the public routes with header and footer
  if (!currentUser) {
    return(<Login/>)
    return (
      <Router>
        <Header /> {/* Include Header component */}
        <Container>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/*" element={<Page404 />} />
          </Routes>
        </Container>
        <Footer /> {/* Include Footer component */}
      </Router>
    );
  }
  

  // If the user is authenticated, show the protected routes with header and footer
  return (
    <Router>
      <Header /> {/* Include Header component */}
      <Container>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/devices" element={<DevicesPage />} />
          <Route path="/data" element={<DataPage />} />
          <Route path="/new" element={<NewDevice />} />
          <Route path="/targets" element={<TargetsPage />} />
          <Route path="/stats" element={<StatsPage />} />
          <Route path="/settings" element={<UserSettingsPage />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/*" element={<Page404 />} />
        </Routes>
      </Container>
      <DataDialog open={false} onClose={() => {}} /> {/* Manage dialog globally if necessary */}

      <Footer /> {/* Include Footer component */}
    </Router>
  );
}

export default App;
