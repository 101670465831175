import React, { useState } from 'react';
import { Button, Container, TextField, Typography } from '@mui/material';
import { auth } from '../../services/fbConfig'; // Ensure this path is correct
import { GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword } from 'firebase/auth';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      // Redirect or perform any post-login actions here
    } catch (err) {
      setError('Failed to log in with Google. Please try again.');
      console.error('Google login error:', err);
    }
  };

  const handleEmailPasswordLogin = async (event) => {
    event.preventDefault();
    setError(''); // Clear any existing error messages

    try {
      await signInWithEmailAndPassword(auth, email, password);
      // Redirect or perform any post-login actions here
    } catch (err) {
      setError('Failed to log in with email and password. Please check your credentials and try again.');
      console.error('Email/password login error:', err);
    }
  };

  return (
    <Container maxWidth="sm" style={{ marginTop: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Login
      </Typography>

      {error && <Typography color="error">{error}</Typography>}

      <form onSubmit={handleEmailPasswordLogin} style={{ marginBottom: '20px' }}>
        <TextField
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          required
          margin="normal"
        />
        <TextField
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          required
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Login with Email
        </Button>
      </form>

      <Button
        variant="contained"
        color="secondary"
        onClick={handleGoogleLogin}
        fullWidth
        style={{ marginTop: '10px' }}
      >
        Login with Google
      </Button>
    </Container>
  );
};

export default Login;
