import React, { useState } from 'react';
import { TextField, Button, Container } from '@mui/material';

const TargetForm = ({ onSubmit }) => {
  const [targetValue, setTargetValue] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!targetValue || !startDate || !endDate) {
      setError('All fields are required.');
      return;
    }

    if (new Date(startDate) > new Date(endDate)) {
      setError('Start date cannot be later than end date.');
      return;
    }

    try {
      await onSubmit({ target_value: targetValue, start_date: startDate, end_date: endDate });
      setTargetValue('');
      setStartDate('');
      setEndDate('');
    } catch (error) {
      setError('Failed to submit the target. Please try again.');
    }
  };

  return (
    <Container maxWidth="sm" style={{ marginTop: '20px' }}>
      <h2>Set Target</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <form onSubmit={handleSubmit}>
        <TextField
          label="Target Value"
          type="number"
          value={targetValue}
          onChange={(e) => setTargetValue(e.target.value)}
          required
          fullWidth
          margin="normal"
        />
        <TextField
          label="Start Date"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          required
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label="End Date"
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          required
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Submit
        </Button>
      </form>
    </Container>
  );
};

export default TargetForm;
