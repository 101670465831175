import React, { useState, useEffect } from 'react';
import { listTargets, setTarget, updateTarget, deleteTarget } from '../services/api';
import TargetForm from '../components/TargetForm';
import TargetList from '../components/TargetList';

const TargetsPage = () => {
  const [targets, setTargets] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchTargets();
  }, []);

  const fetchTargets = async () => {
    try {
      const targetData = await listTargets();
      setTargets(targetData);
    } catch (error) {
      setError('Failed to fetch targets.');
    }
  };

  const handleAddTarget = async (target) => {
    try {
      await setTarget(target);
      fetchTargets(); // Refresh the target list after adding a new target
    } catch (error) {
      setError('Failed to add target.');
    }
  };

  const handleUpdateTarget = async (targetId, target) => {
    try {
      await updateTarget(targetId, target);
      fetchTargets(); // Refresh the target list after updating a target
    } catch (error) {
      setError('Failed to update target.');
    }
  };

  const handleDeleteTarget = async (targetId) => {
    try {
      await deleteTarget(targetId);
      fetchTargets(); // Refresh the target list after deleting a target
    } catch (error) {
      setError('Failed to delete target.');
    }
  };

  return (
    <div>
      <h2>Manage Targets</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <TargetForm onSubmit={handleAddTarget} />
      <TargetList targets={targets} onEdit={handleUpdateTarget} onDelete={handleDeleteTarget} />
    </div>
  );
};

export default TargetsPage;
