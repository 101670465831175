import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AuthProvider } from './context/AuthContext'; // Import AuthProvider to provide auth context
//import './styles/App.css'; // Import global styles (if any)

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <AuthProvider> {/* Wrap the App component with AuthProvider to make authentication context available */}
      <App />
    </AuthProvider>
  </React.StrictMode>
);
