import React, { useState } from 'react';
import { getNewDeviceData } from '../services/api';
import { Button, CircularProgress, Alert, TextField, IconButton } from '@mui/material';
import { ContentCopy as ContentCopyIcon } from '@mui/icons-material';

const NewDevicePage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [deviceId, setDeviceId] = useState('');

  const handleFetchNewDeviceData = async () => {
    setLoading(true);
    setError('');
    try {
      const result = await getNewDeviceData();
      if (result.success && result.deviceId) {
        setDeviceId(result.deviceId);
      } else {
        setError('Failed to retrieve device ID.');
      }
    } catch (error) {
      setError('Failed to fetch new device data.');
    } finally {
      setLoading(false);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(deviceId);
    alert('Device ID copied to clipboard!');
  };

  return (
    <div>
      <h2>Get New Device Data</h2>
      <Button variant="contained" color="primary" onClick={handleFetchNewDeviceData} disabled={loading}>
        {loading ? <CircularProgress size={24} /> : 'Fetch Data'}
      </Button>
      {error && <Alert severity="error" style={{ marginTop: '10px' }}>{error}</Alert>}
      {deviceId && (
        <div style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}>
          <TextField
            label="Device ID"
            value={deviceId}
            InputProps={{ readOnly: true }}
            fullWidth
          />
          <IconButton onClick={handleCopy} color="primary" aria-label="copy device ID">
            <ContentCopyIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default NewDevicePage;
