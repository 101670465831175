import React from 'react';
import { List, ListItem, ListItemText, Divider, Button } from '@mui/material';

const DeviceList = ({ devices, onRemoveDevice }) => {
  if (!devices || devices.length === 0) {
    return <p>No devices available.</p>;
  }

  return (
    <List>
      {devices.map((device) => (
        <React.Fragment key={device.id}>
          <ListItem>
            <ListItemText primary={`Device Name: ${device.device_name}`} secondary={`API Key: ${device.api_key}`} />
            <Button color="secondary" onClick={() => onRemoveDevice(device.id)}>
              Remove
            </Button>
          </ListItem>
          <Divider />
        </React.Fragment>
      ))}
    </List>
  );
};

export default DeviceList;
