import { auth } from './fbConfig';

// Authentication Services
export const signupWithEmailPassword = (email, password) => {
    return auth.createUserWithEmailAndPassword(email, password);
};

export const loginWithEmailPassword = (email, password) => {
    return auth.signInWithEmailAndPassword(email, password);
};

export const logout = () => {
    return auth.signOut();
};

export const resetPassword = (email) => {
    return auth.sendPasswordResetEmail(email);
};

export const updateEmail = (email) => {
    return auth.currentUser.updateEmail(email);
};

export const updatePassword = (password) => {
    return auth.currentUser.updatePassword(password);
};

// Token Management
let cachedToken = null;
let tokenPromise = null;

const fetchIdToken = () => {
    if (!auth.currentUser) {
        throw new Error('No user logged in');
    }
    return auth.currentUser.getIdToken(true);
};

export const getUser = () => {
    if (!auth.currentUser) {
        //throw new Error('No user logged in');
        return null
    }
    return auth.currentUser
}

export const getIdToken = () => {
    // If there's a token promise in flight, return it
    if (tokenPromise) return tokenPromise;

    // If there's a valid cached token, use it
    if (cachedToken && cachedToken.expirationTime > new Date().getTime()) {
        return Promise.resolve(cachedToken.token);
    }

    // Otherwise, fetch a new token
    tokenPromise = fetchIdToken().then(token => {
        cachedToken = {
            token,
            expirationTime: new Date().getTime() + (60 * 60 * 1000) // Assuming 1 hour validity
        };
        tokenPromise = null; // Clear the promise after it resolves
        return token;
    }).catch(error => {
        tokenPromise = null; // Ensure to clear promise on error as well
        throw error;
    });

    return tokenPromise;
};
