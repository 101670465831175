import React from 'react';
import { Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer style={{ padding: '20px 0', marginTop: 'auto', backgroundColor: '#f5f5f5', textAlign: 'center' }}>
      <Container>
        <Typography variant="body2" color="textSecondary" style={{ marginBottom: '10px' }}>
          © 2024 Wavesync. All rights reserved.
        </Typography>
        <Typography variant="body2" color="primary" component={Link} to="/terms" style={{ marginRight: '15px', textDecoration: 'none' }}>
          Terms
        </Typography>
        <Typography variant="body2" color="primary" component={Link} to="/privacy" style={{ textDecoration: 'none' }}>
          Privacy
        </Typography>
      </Container>
    </footer>
  );
};

export default Footer;
