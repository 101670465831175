// src/context/AuthContext.js
import React, { useContext, useState, useEffect, createContext } from 'react';
import { auth } from '../services/fbConfig'; // Ensure this path is correct
import Loading from '../components/Loading';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function logout() {
    return new Promise((resolve, reject) => {
      auth.signOut()
        .then(() => {
          console.log('User logged out successfully');
          resolve(); // Resolve the promise after successful logout
        })
        .catch((error) => {
          console.error('Error logging out:', error);
          reject(error); // Reject the promise in case of an error
        });
    });
  }
  

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword
  };

  return (
    <AuthContext.Provider value={value}>
      {loading ? <Loading /> : children}
    </AuthContext.Provider>
  );
};
