import React from 'react'
import { NavLink } from 'react-router-dom'
import Container from '@mui/material/Container';



const View = () => {

    return (
            <Container >
                <h1><strong>404.</strong> Could not find page.</h1>
                <p>We could not find the page you were looking for.</p>
            </Container>
        )
    
}
export default View
