import React, { useState, useEffect } from 'react';
import { TextField, Button, Container } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import { getUserSettings, updateUserSettings } from '../services/api';

const UserSettings = () => {
  const { currentUser, updateEmail, updatePassword } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [settings, setSettings] = useState({});
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      const userSettings = await getUserSettings();
      setSettings(userSettings);
      setEmail(userSettings.email || '');
    } catch (error) {
      setError('Failed to load user settings.');
    }
  };

  const handleSaveChanges = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');

    try {
      if (email !== currentUser.email) {
        await updateEmail(email);
      }
      if (password) {
        await updatePassword(password);
      }
      await updateUserSettings(settings);
      setMessage('Settings updated successfully.');
    } catch (error) {
      setError('Failed to update settings. Please try again.');
    }
  };

  return (
    <Container maxWidth="sm">
      <h2>User Settings</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {message && <p style={{ color: 'green' }}>{message}</p>}
      <form onSubmit={handleSaveChanges}>
        <TextField
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          margin="normal"
          placeholder="Leave blank to keep the current password"
        />
        {/* Additional settings fields */}
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Save Changes
        </Button>
      </form>
    </Container>
  );
};

export default UserSettings;
